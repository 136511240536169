import React from 'react';
import PropTypes from 'prop-types';

import useResourceQuery from 'dpl/shared/fetching/hooks/useResourceQuery';

import { breedOverviewPagesResourceDefinition } from './utils/queryDefinitions';
import BreederProfileLocationLinksGroup from './BreederProfileLocationLinksGroup';

export default function BreederProfileBreedOverviewPages({ header, limit }) {
  const { value: overviewPagesData } = useResourceQuery(
    breedOverviewPagesResourceDefinition(limit)
  );

  return (
    <BreederProfileLocationLinksGroup
      linksData={overviewPagesData}
      header={header}
      wideLayout
      linkType="breed_overview"
    />
  );
}

BreederProfileBreedOverviewPages.propTypes = {
  header: PropTypes.string,
  limit: PropTypes.number
};

BreederProfileBreedOverviewPages.defaultProps = {
  header: 'Learn about similar breeds',
  limit: null
};
